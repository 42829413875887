@use "sass:math"

@import '../helpers'

$chart-legend-point-size: 20px

@mixin legend-swatch($add-border: true)
  content: ''
  display: block
  height: $chart-legend-point-size
  width: $chart-legend-point-size
  @include media-breakpoint-down(up)
    height: $chart-legend-point-size
    width: $chart-legend-point-size
  background: $brand-primary
  @if $add-border == true
    border: 2px solid $white

.c-chart-legend
  display: flex
  flex-wrap: wrap
  justify-content: center
  align-items: flex-start
  padding-left: space(8)
  margin-top: space(4)
  padding-top: space(4)
  border-top: 2px solid $border-color-secondary
  max-height: 90vh
  overflow: auto

.c-chart-legend--left
  justify-content: flex-start
  padding-left: 0

.c-chart-legend--secondary
  margin-top: space(5)

.c-chart-legend__item
  display: flex
  flex-direction: column
  margin: 0 space(4)
  align-items: center
  text-align: center
  opacity: .5
  transition: .2s
  cursor: pointer
  &:not(.active):hover
    opacity: .6
  &.active
    opacity: 1

.c-chart-legend__item--sm
  max-width: 100px

.c-chart-legend__item--swatch
  flex-direction: row
  margin-right: space(4)
  align-items: flex-start
  margin-top: space(1)
  margin-bottom: space(1)
  cursor: normal
  opacity: 1
  pointer-events: none
  align-items: center

.c-chart-legend__item--swatch .c-chart-legend__label
  font-size: rem(13px)
  span
    text-align: left

.c-chart-legend__swatch
  @include swatch(rem($chart-legend-point-size))
  border: 1px solid $border-color-secondary

.c-chart-legend__swatch--map
  background: rgba(#d98350, .25)
  border: 2px solid #d98350
  border-radius: 50%

.c-chart-legend__visual
  max-width: 100px
  min-width: 75px
  @include media-breakpoint-up(md)
    max-width: 200px
    min-width: 150px
  border: 0
  border-bottom: 2px solid #CCC
  min-height: $chart-legend-point-size
  position: relative
  margin: space(4)
  display: flex
  align-items: center
  padding-left: space(4)
  padding-right: space(4)
  &::after,
  &::before
    @include legend-swatch
    position: absolute
    top: 50%
    left: space(4)
    right: auto
    border-radius: 50%
  &::after
    right: space(4)
    left: auto
  @include media-breakpoint-down(sm)
    &::after
      display: none

@each $index, $color in $chart-colors
  .c-chart-legend__visual--#{$index}
    border-color: $color
    &::after,
    &::before
      background: $color
    @if $index == 2 or $index == 5
      &::after,
      &::before
        border-radius: 0
    @else if $index == 3 or $index == 6
      &::after,
      &::before
        background: none
        border-radius: 0
        width: 0
        height: 0
        border-style: solid
        border-width: 0 10px 17.3px 10px
        border-color: transparent transparent $color transparent
    @if $index > 3 and $index != 6
      &::after,
      &::before
        border: 3px solid $color
        background: $white
        height: math.div($chart-legend-point-size, 1.33)
        width: math.div($chart-legend-point-size, 1.33)

.c-chart-legend__visual--leveled
    border-color: #CCCCCC
    &::after
      background: map-get($chart-reference-level-colors, 1)
    &::before
      background: map-get($chart-reference-level-colors, length($chart-reference-level-colors))

.c-chart-legend__visual--point
  border: 0
  max-width: $chart-legend-point-size * 1.5
  margin-top: space(6)
  &::before
    left: 50%
    transform: translate(-50%, -50%)
    height: $chart-legend-point-size
    width: $chart-legend-point-size
    @include media-breakpoint-up(sm)
      height: $chart-legend-point-size * 1.5
      width: $chart-legend-point-size * 1.5
  &::after
    display: none

@each $index, $color in $chart-colors
  .c-chart-legend__visual--point-#{$index}
    &::before
      background: $color

.c-chart-legend__visual--line
  &::before,
  &::after
    display: none
  @each $index, $color in $chart-colors
    &-#{$index}
      border-color: $color

.c-chart-legend__item--swatch
  text-align: left
  align-items: flex-start
  &.align-items-center .c-chart-legend__label
    margin-top: 0

.c-chart-legend__swatch-wrapper
  border: 0
  margin-right: space(1)
  &:last-child
    margin-bottom: 0
  &::before
    border-radius: 2px
    @include legend-swatch($add-border: false)
  @each $index, $color in $chart-colors
    &--#{$index}
      &::before
        background: $color

.c-chart-legend__swatch--line
  height: 5px
  border: 0
  align-self: center

.c-chart-legend__swatch--dotted
  height: 1px
  border: 0
  border-top: 5px dotted black
  align-self: center
  background: none
  border-radius: 0

.c-chart-legend__label
  font-weight: font-weight(semibold)

.c-chart-legend__label span
  display: block
  &:first-of-type
    @include bolden
  &:last-of-type
    color: $gray-800

.c-chart-legend--spread
  justify-content: space-between
  margin: 0
  padding: 0
