@import '../../helpers'
@import '../../tools/mixins/dashboard'

$timeline-item-title-color: $black
$timeline-item-inactive-title-color: #777777
$timeline-item-title-hover-color: darken(get-dash-color(link), 5%)
$timeline-item-inactive-color: #EFEFEF

.c-dashboard-timeline
  display: flex
  flex-direction: column
  flex: 1 1 100%
  max-width: 100%

.c-dashboard-timeline__wrapper
  position: relative
  display: flex
  flex: 1 1 auto
  margin-bottom: space(2)
  &::after
    content: ''
    position: absolute
    width: space(6)
    right: 0
    top: 0
    bottom: 15px // Account for scrollbar add extra space for IE scrollbars
    background: rgb(255,255,255)
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)
    z-index: 10
    pointer-events: none

.c-dashboard-timeline__canvas
  position: relative
  flex: 1 1 auto
  max-width: 100%
  overflow-x: scroll
  white-space: normal
  padding: space(7) 5% space(6) 0

.c-dashboard-timeline__grid-line
  position: absolute
  width: 2px
  border-radius: 2px
  background: #DDD
  left: 5px
  top: 0
  bottom: 0


.c-dashboard-timeline__header
  flex: 0 1 auto
  margin-bottom: space(2)

.c-dashboard-timeline__header h3
  display: flex
  justify-content: space-between

.c-dashboard-timeline__months
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  width: 100%
  margin-bottom: space(2)

.c-dashboard-timeline__month
  position: absolute
  left: 10px
  white-space: nowrap

.c-dashboard-timeline__item
  position: relative
  display: block
  z-index: 10
  min-width: 90%
  padding: space(3) space(4)
  margin-bottom: space(2)
  margin-right: space(4)
  border-radius: 23px
  background:  $timeline-item-inactive-color
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
  text-decoration: none
  cursor: pointer
  border: 1px solid transparent
  &:hover,
  &:focus
    box-shadow: 0 3px 5px 0px rgba($black,.05)
    .c-dashboard-timeline__title
      color: $timeline-item-title-hover-color
    border-color: rgba(get-dash-color(link), .4)
    outline: 0
  &.active
    background: transparent linear-gradient(90deg, #CEE7FF 0%, #CEE7FF 60%, #F3F9FF 83%, #FFFFFF 100%) 0% 0% no-repeat padding-box
    .c-dashboard-timeline__title
      color: $black

  &.selected
    box-shadow: 0 3px 10px 0px rgba($black,.1)
    border-color: get-dash-color(link)
    .c-dashboard-timeline__title
      color: $black

.c-dashboard-timeline__item-text
  position: sticky
  left: space(4)

.c-dashboard-timeline__title
  font-size: 16px
  font-weight: font-weight(bold)
  color: $timeline-item-inactive-title-color
  letter-spacing: .4px

.c-dashboard-timeline__sub-title,
.c-dashboard-timeline__context
  line-height: 17px
  color: $black

.c-dashboard-timeline__sub-title
  padding: 0
  margin: 0
  list-style-type: none
  &,
  li
    font-size: rem(14px)
    letter-spacing: .35px
    line-height: 19px

.c-dashboard-timeline__context
  font-size: rem(12px)
  letter-spacing: .3px

.c-dashboard-timeline__footer
  display: flex
