@import '../helpers'

.c-dropdown
  max-width: 100%
  @include media-breakpoint-up(md)
    max-width: auto

.c-dropdown__label
  @include input-label
  margin-bottom: space(2)

.c-dropdown__intro
  background: $card-color
  padding: space(2) space(4) space(1) space(4)
  border-bottom: 2px solid $border-color-secondary

.c-dropdown__button
  @include dropdown

.c-dropdown__button,
.c-dropdown--constrained .c-dropdown__button
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

.c-dropdown--constrained .c-dropdown__button
  @include media-breakpoint-up(sm)
    width: auto
    max-width: 300px

.c-dropdown--constrained .c-dropdown__menu
  overflow: auto
  @include media-breakpoint-up(sm)
    max-width: 50vh
    min-width: 550px !important
    max-height: 90vh

.c-dropdown__button--link
  margin-bottom: -1px
  border-bottom-left-radius: 0
  border-bottom-right-radius: 0
  padding-left: space(4)
  padding-right: space(8)

// .c-dropdown__button--constrained

.c-dropdown--sm .c-dropdown__button--sm,
.c-dropdown__button--sm
  min-width: auto

.c-dropdown.show .c-dropdown__button
  border-bottom-left-radius: 0
  border-bottom-right-radius: 0
  &::after
    content: '\f106' // angle-up

.c-dropdown__menu
  min-width: 100%
  border: 1px solid $brand-primary
  border-top-left-radius: 0
  border-top-right-radius: 0
  border-bottom-right-radius: $border-radius-default
  border-bottom-left-radius: $border-radius-default
  margin-top: -1px
  padding: 0
  @include media-breakpoint-up($nav-switch-breakpoint)
    min-width: 300px
    max-width: 400px


.c-dropdown--sm .c-dropdown__menu--sm
  min-width: auto

.c-dropdown__item
  width: 100%
  white-space: normal
  &.active
    font-weight: bold
    pointer-events: none
  &.disabled
    color: $gray-500
    pointer-events: none

.c-dropdown__item,
.c-dropdown__menu-heading,
.c-dropdown__section
  padding: rem(4px) space(2)

.c-dropdown__section
  @include media-breakpoint-up(md)
    > div
      column-count: $default-col-count
      column-gap: space(3)
      column-count: 2

.c-dropdown__section span
  display: block

.c-dropdown__section-title,
.c-dropdown__section-sub-title
  padding-left: space(2)
  padding-right: space(2)
  font-weight: bold

.c-dropdown__section-title
  background: $border-color-secondary
  margin-top: space(3)
  margin-bottom: space(2)
  padding-top: space(1)
  padding-bottom: space(1)

.c-dropdown__section-sub-title
  background: lighten($border-color-secondary, 5%)
  position: sticky
  top: 0

a.c-dropdown__item
  display: block
  &:focus,
  &:hover
    text-decoration: none
    background: lighten($border-color-secondary, 5%)
  &:active
    color: $black
  &:last-of-type
    border: 0

.c-dropdown__menu-heading
  padding-top: space(3)
  margin-bottom: 0

.c-dropdown__menu-container
  padding: space(3) space(4)

.c-dropdown__menu-container--border-btm
  border-bottom: 2px solid $border-color-secondary

.c-dropdown__menu-container--border-top
  border-top: 2px solid $border-color-secondary

.c-dropdown__menu hr
  border-color: $border-color-secondary

.c-dropdown--comparison .c-dropdown__button
  border-color: $metric-comparison
  &,
  &::after
    color: $metric-comparison
  &:focus,
  &:hover
    background: $metric-comparison
    border-color: $metric-comparison
    outline: 0
    box-shadow: none
    &,
    &::after
      color: $white

.c-dropdown--inline-flex
  display: inline-flex
  flex-direction: column
