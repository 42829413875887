@import '../helpers'

$item-base-padding: 40px

.c-list
  &,
  ul
    @include list-unstyled

.c-list
  @include flex(1, 1, auto)
  overflow: auto
  margin-bottom: 0
  padding-top: 16px // Align with next app
  padding-bottom: space(4)

.c-list--padded-left
  padding-left: space(2)

.c-list__section
  margin-bottom: space(4)

.c-list__title
  padding-top: space(2)
  padding-bottom: space(2)
  padding-left: space(4)
  padding-right: space(2)
  position: sticky
  top: 0
  z-index: 10
  background: $white
  margin-bottom: 0

.c-list__item
  position: relative
  display: flex
  padding-left: space(4)
  flex-direction: column
  border-bottom: 1px solid $border-color-secondary
  @include transition($duration: .1s)
  &.no-border
    border: 0
  &.active > .c-list__link
    @include bolden
  &.primary
    border-width: 3px

.c-list__item:not(.c-list__item--heading)
  &:hover
    background: $card-color
  &:active
    background: $white

.c-list__item--warning
  @include transition($duration: .1s)
  &:not(.c-list__item--heading):focus,
  &:not(.c-list__item--heading):hover
    background: $brand-warning
  > a
    padding-top: space(3)
    padding-bottom: space(3)
    @include transition($duration: .1s)
    &::before
      content: '\f071'
      @include font-icon(rem(18))
      margin-right: space(1)
.c-list__item--pandemic
  > a
    &::before
      content: ''


.c-list__note
  display: flex
  flex-direction: column
  padding: 0 space(1) space(1) 0
  margin-bottom: space(1)
  @include default-body-font($size: 14px, $color: $heading-secondary-color)
  border-bottom: 1px solid $gray-100
  &:last-of-type
    border: 0

.c-list__item--parent
  padding-left: space(6)
  &.active .c-list__item-toggle
    span::before
      content: '\f107'
    &:hover,
    &:focus
      span::before
        font-weight: 700

  .c-list__item-toggle:hover,
  .c-list__item-toggle:focus
    span::before
      font-weight: 700

.c-list__item--child
  border: 0
  padding-left: space(6)

.c-list__item--child ul
  padding-left: space(3)

.c-list__item--child ul li
  font-weight: normal !important
  padding-left: space(2)

.c-list__item--parent > a
  padding-top: space(2)
  padding-bottom: space(2)

.c-list__item-toggle
  position: absolute
  left: -5px
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  min-width: $item-base-padding
  &,
  &:hover,
  &:focus
    text-decoration: none
  span
    padding-left: space(4)
    &::before
      @include font-icon(rem(18))
      content: '\f105'
      font-weight: 800

.c-list__link
  @include flex(1, 1, auto)
  @include default-body-font($size: 14px, $color: $link-color)
  display: flex
  align-items: center
  padding: space(1) space(2) space(1) 0
  text-decoration: none
  &::after
    content: '\f101'
    @include font-icon(rem(16px))
    color: transparent
    margin-left: auto
  &:focus,
  &:hover
    text-decoration: none
    color: $brand-secondary
    &::after
      color: $brand-secondary
  &.active
    font-weight: bold
  span
    padding-right: space(1)
  &.active.hide-when-active
    visibility: hidden
    opacity: 0
  &.disabled
    color: rgba($brand-primary, .4)
    pointer-events: none


.c-list__link span
  &:empty
    display: none

.c-list__item--heading
  margin-left: space(4, true)
  margin-top: space(2)
  background: $gray-100
  padding: space(2) space(4) space(2) space(4)
  border-bottom: 1px solid $brand-primary
  @include font-semibold(rem(16px), $heading-secondary-color, $heading: true)
  text-transform: uppercase
  &:after
    content: ''

.c-list__children
  // Explicitly declare for IE 11
  // @include flex(0, 0, 0)
  flex-grow: 0
  flex-shrink: 0
  flex-basis: 0px
  overflow: hidden
  padding: 0
  &.active
    // Explicitly declare for IE 11
    // @include flex(1, 1, auto)
    flex-grow: 1
    flex-shrink: 1
    flex-basis: auto
    border-bottom: 1px solid $border-color-secondary
    align-self: stretch

.c-list__child
  @include list-unstyled
  margin-left: space(3)

.c-list__child li
  &:last-of-type
    border-bottom: 0

.c-list__group
  display: flex
  flex-direction: column
  &:last-child > li
    border: 0

// Tree mod
.c-list--light .c-list__link
  color: $white
  font-weight: bold

$list-tree-line-color: rgba($white, .5)
$list-active-color: $card-color
.c-list--tree .c-list__items
  padding: space(2) 0 0 space(4)
  position: relative
  flex: none
  &::before
    content: ''
    position: absolute
    width: 2px
    background: $list-tree-line-color
    left: space(4)
    top: 0
    bottom: 0
    transition: .25s background ease-ine-out
  &:hover
    &::before
      background: $list-active-color

.c-list--tree .c-list__item
  border-bottom: 0
  padding-left: 0
  margin-left: rem(2px)
  margin-bottom: rem(4px)
  &:hover
    background: none

.c-list--tree .c-list__link
  display: flex
  align-items: center
  text-decoration: none
  padding: 0
  letter-spacing: .03em
  font-size: rem(16px)
  span
    padding: space(2) space(2)
    border-top-left-radius: 4px
    border-bottom-left-radius: 4px
    display: block
    @include flex(1, 1, auto)
  &.active,
  &:hover,
  &:focus
    span
      background: $list-active-color
      color: $brand-secondary
    &::before
      background: $list-active-color
  &::before
    content: ''
    width: space(5)
    height: 2px
    background: $list-tree-line-color
    transition: .25s background ease-ine-out
  &::after
    content: ''

.c-list--tree
  padding-bottom: 0
  flex: none
  h2
    @include font-heading(rem(16px), $list-active-color, $lh: line-height(18, 16), $weight: 600)
    padding: space(2) space(4) space(2) space(4)
    text-transform: uppercase
    margin-top: space(2)
    background: rgba($list-active-color, .75)
    color: black
    @include flex(1, 1, 100%)
    margin: 0
