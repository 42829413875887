@mixin list-unstyled {
  padding-left: 0;
  list-style: none;
}

@mixin map-legend-gradient { }

@mixin swatch($size, $color: $white) {
  @include flex(0, 0, auto);
  height: $size;
  width: $size;
  border-radius: 4px;
  margin-right: space(1);
  background-color: $white;
  border: 1px solid $border-color-secondary
}

// for some reason this isn't pulling in from bootstrap, at least not in footer.sass
@mixin img-fluid {
  // Part 1: Set a maximum relative to the parent
  max-width: 100%;
  // Part 2: Override the height to auto, otherwise images will be stretched
  // when setting a width and height attribute on the img element.
  height: auto;
}

@mixin constrained-container {
  max-width: $max-container-width;
  @include flex(1, 1, auto);
}

@mixin dashed-line($a: 10, $b: 9, $color: $brand-primary, $width: 2px) {
  stroke-dasharray: $a $b;
  stroke-width: $width;
  stroke: $color;
  stroke-linecap: round;
}

@mixin dotted-line($a: 2, $b: 4, $color: $border-color-primary, $width: 2px) {
  stroke: $color;
  stroke-dasharray: $a $b;
  stroke-width: $width;
  stroke-linecap: round;
}

@mixin tooltip {
  border: 1px solid $brand-primary;
  background: $white;
  box-shadow: 0 2px 8px rgba($black, .2);
  border-radius: $border-radius-default;
  max-width: rem(300px);
}

@mixin transition($duration: $transition-duration, $prop: false, $timing-fn: ease-in-out) {
  @if ($prop) { transition-property: $prop; }
  transition-duration: $duration;
  transition-timing-function: $timing-fn;
}

@mixin simple-dropdown-button {
  border: 0;
  background: none;
  &:active,
  &:hover {
    color: $brand-secondary;
  }
}

@mixin line-height($lh, $font-size) {
  line-height: line-height($lh, $font-size)
}

@mixin heading-sticky($padding-top: space(2), $margin-btm: space(4), $bg-color: rgba($white, .9)) {
  position: sticky;
  top: 100px;
  padding-top: $padding-top;
  margin-bottom: $margin-btm;
  background: $bg-color;
  z-index: stack-order(btm);
}

@mixin hero-image($position: center center) {
  background-image: url('~images/landing-hero-sm.jpg');
  background-position: $position;
  background-size: cover;
  @include media-breakpoint-up(md) {
    background-image: url('~images/landing-hero-md.jpg');
  }
  @include media-breakpoint-up(lg) {
    background-image: url('~images/landing-hero-lg.jpg');
  }
}

@mixin topic-full-bleed($padded: false) {
  margin-left: (-#{space(3)});
  margin-right: (-#{space(4)});
  @if ($padded) {
    padding-left: space(4);
    padding-right: space(4);
  }
}

@mixin topic-header {
  padding-top: 0;
  flex-wrap: wrap;
  align-items: center;
  background: $gray-090;
  padding-left: space(2);
  padding-right: space(4);
  width: 100%;
}

@mixin topic-title($size: 'md', $color: $brand-secondary) {
  @include h2($size: $size);
  display: flex;
  align-items: center;
  border-bottom: 2px solid $brand-primary;
  padding-bottom: space(1);
  color: $color;
}

@mixin topic-title-full-bleed {
  @include topic-full-bleed(true);
  border-color: $brand-secondary;
  margin-bottom: space(4);
}

@mixin card-link {
  padding: space(2) space(4);
  border: 1px solid transparent;
  background: $card-footer-link-color;
  font-weight: 600;
  text-decoration: none;
  &:hover {
    background: $brand-primary;
    color: $white;
    border-color: $brand-primary;
    text-decoration: none;
  }
}

@mixin ellipsis {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}

@mixin link-arrows($size: rem(28)) {
  &::after {
    content: '\f101';
    @include font-icon($size: $size);
    font-weight: 800;
    margin-left: space(2);
    color: $border-color-secondary;
    transition: .3s color ease-in-out;
  }
}

@mixin flex($grow: 1, $shrink: 1, $basis: 100%) {
  flex: #{$grow} #{$shrink} #{$basis};
  flex-grow: $grow;
  flex-shrink: $shrink;
  flex-basis: $basis;
}

@mixin search-icon-before($color: $gray-500) {
  &::before {
    content: '\f002';
    color: $color;
    @include font-icon(rem(14));
    margin-right: space(2);
  }
}

@import 'bootstrap_polyfills';
@import 'form';
@import 'preact_range_slider_mixin';
@import 'topic';
@import 'story';
@import 'mui';
