@use "sass:math"

@import '../helpers'

$dashboard-gutter: space(4)
.o-dashboard
  width: 100%
  flex: 1 1 auto
  padding-bottom: rem(100)
  background: get-dash-color(canvas-l)
  margin: 0 auto
  h1
    @include font-open-sans($size: rem(30px), $color: get-dash-color(h1), $weight: font-weight(bold))
    @include media-breakpoint-up(md)
      font-size: rem(40px)
    margin-bottom: space(4)
  .o-dashboard__section
    display: block
    margin-bottom: space(4)
  > .container
    padding-left: $container-padding-x
    padding-right: $container-padding-x
  .o-dashboard__masthead
    @include media-breakpoint-up(md)
      margin-top: space(4)
  .o-dashboard__breadcrumbs
    background: $white
    padding: space(2) space(4)
    margin-left: - space(3)
    margin-right: - space(3)
    margin-bottom: space(4)
    @include media-breakpoint-up(md)
      padding: 0
      background: none
      margin-left: 0
      margin-right: 0

  .container
    @include media-breakpoint-down(md)
      padding-left: space(2)
      padding-right: space(2)

.o-dashboard__breadcrumbs
  display: flex
  justify-content: space-between
  flex-wrap: wrap

.o-dashboard__header
  background: $border-color-secondary
  position: relative
  width: 100%
  display: flex
  flex-flow: row wrap
  align-items: baseline
  .dropdown-toggle
    background: none
    color: $brand-primary
    border: 0
    font-size: 24px
    padding: space(1)
    cursor: pointer

.o-dashboard__header-title
  padding-bottom: 0px
  margin-bottom: 0px
  text-transform: uppercase
  margin-right: space(4)
  font-size: rem(24)
  line-height: math.div(28, 24)
  @include media-breakpoint-up(sm)
    font-size: rem(34)
    line-height: 1

.o-dashboard__period
  display: flex
  flex-flow: row wrap
  font-weight: bold
  @include media-breakpoint-up(sm)
    font-size: large

.o-dashboard__period-dates-label
  margin-right: space(2)

.o-dashboard__period-dates
  background: white
  border-radius: space(1)
  padding: 0 space(4)
  margin-right: space(4)
  white-space: nowrap

.o-dashboard__header-links
  display: flex
  padding-top: 5px
  @include media-breakpoint-up(sm)
    margin-left: auto

.o-dashboard__header-link
  padding-right: 10px
  white-space: nowrap

.o-dashboard__section
  display: flex
  margin-bottom: space(7)

.o-dashboard__col
  display: flex
  flex-direction: column
  @include flex(1, 1, 100%)
  padding-right: $dashboard-gutter

[class^='o-dashboard__col']
  &:last-of-type
    padding-right: 0

.o-dashboard__col--sm
  @include media-breakpoint-up(sm)
    flex: 1 1 30%
    max-width: 30%

.o-dashboard__col--md
  @include media-breakpoint-up(sm)
    @include flex(1, 1, 50%)
    max-width: 50%

.o-dashboard__col--lg
  @include media-breakpoint-up(sm)
    flex: 1 1 70%
    max-width: 70%

.o-dashboard--he
  padding: space(2) 0
  background: dash-color(canvas-bg)
  @include media-breakpoint-up($nav-switch-breakpoint)
    padding: space(6) space(2)
  $link-color: dash-color(link-color-primary)
  a
    color: $link-color
    &:not([class*='tab'])
      &:focus,
      &:hover
        color: darken($link-color, 10%)

.o-dashboard__undernav
  background-color: #8b0505
  > p
    max-width: 20vw
    font-size: 14px
    line-height: 15px
    text-align: right

.o-dashboard__undernav-link
  font-size: 14px
  padding: 0.5em 0em 0em 0em
  margin-right: 1.75em
  margin-bottom: 8px
  color: #e6e6e6
  position: relative
  white-space: nowrap
  text-decoration: none

.o-dashboard__undernav-link.active
  font-weight: 600
  color: #f0f0f0

.o-dashboard__undernav-link:hover
  color: #ffffff

.o-dashboard__undernav-link.active:before
  top: 20px
  left: 50%
  border: solid transparent
  content: " "
  height: 0
  width: 0
  position: absolute
  pointer-events: none
  border-color: rgba(255, 255, 255, 0)
  border-bottom-color: #fff
  border-width: 8px
  margin-left: -8px
